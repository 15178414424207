/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import { h, mount } from 'dom-dom';
import ace from 'brace';
require('brace/mode/javascript');
require('brace/theme/dracula');

function ready(fn) {
    if (document.readyState != 'loading'){
      fn();
    } else {
      document.addEventListener('DOMContentLoaded', fn);
    }
}

ready(() => {
  var editor = ace.edit("editor");

  var textarea = document.getElementById('bookmarklet_code');
  textarea.style.visibility = 'hidden';

  editor.getSession().setMode('ace/mode/javascript');
  editor.setTheme('ace/theme/dracula');

  editor.getSession().setValue(textarea.value);

  editor.getSession().on('change', function() {
    textarea.value = editor.getSession().getValue();
  });

  var file_fields = document.getElementById('file-fields');

  function renderRow() {
    return mount(<div class="file_row">
      <input value="" type="text" class="bookmarklet_file" name="bookmarklet[files][][url]" />
      <div class="file_actions">
        <img alt="move up" class="up" src="/assets/arrow_up2-49fbafb2529cdd51f5d66ba14a659d4283e1359b250e6edc5af05ee5a8bb586e.png" />
        <img alt="move down" class="down" src="/assets/arrow_down2-54a484286077dbea557493be5e11e0759a5cd65c72d6fc808325b2ffbb139d5c.png" />
        <img alt="delete" class="delete" src="/assets/delete-64a83c5bf1b9c56356d6b93404db8e357b2bfb262a8c89a20721d3030c437593.png" />
      </div>
    </div>);
  }

  var add_file_button = document.getElementById('add-file');

  add_file_button.addEventListener('click', function() {
    file_fields.append(renderRow());
  });

  function findAncestorThatHasClass(haystack, needle) {
    var result = undefined;

    while ((haystack = haystack.parentNode)) {
      if(haystack.nodeType != 1) {
        return null;
      }

      if(haystack.classList.contains(needle)) {
        return haystack;
      }
    }
  }

  function handleMove(target_row, direction) {
    var prev, next;
    if(direction === 'up' && (prev = target_row.previousElementSibling)) {
      file_fields.insertBefore(target_row, prev);
    } else if(direction === 'down' && (next = target_row.nextElementSibling)) {
      file_fields.insertBefore(next, target_row);
    }
  }

  function handleRemove(target_row) {
    target_row.remove();
  }

  file_fields.addEventListener('click', function(event) {
    event.preventDefault();
    var target = event.target;
    var target_row = findAncestorThatHasClass(event.target, 'file_row');

    if(!target_row) {
      return false;
    }

    if(target.classList.contains('up')) {
      handleMove(target_row, 'up');
    } else if(target.classList.contains('down')) {
      handleMove(target_row, 'down');
    } else if(target.classList.contains('delete')) {
      handleRemove(target_row);
    }

    return false;
  })
})
